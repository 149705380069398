//能量值获取类型
const get_types = {"1":"优惠券","2":"实物","3":"服务"};
//能量值商品标签
const tags = [
    {k:0,v:"无"},
    {k:1,v:"热销"},
    {k:2,v:"推荐"},
    {k:3,v:"限时折扣"},
    {k:4,v:"新品折扣"}
];
//能量值商品状态
const status_vals = {"0":"下架", "1":"上架"};
//能量值商品兑换状态
const get_status_vals = {"0":"待完成","1":"已完成","2":"已取消"};

export {
    get_types,
    tags,
    status_vals,
    get_status_vals
}