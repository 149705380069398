<template>
    <div>
        <tableview ref="table" :url="API.Market.shop.order" v-model="searchData" :checkFun="checkFun">
            <template #search>
                <el-input v-model="searchData.order_no" size="small" clearable placeholder="输入订单号"></el-input>
                <el-select v-model="searchData.send_type" placeholder="请选择发货方式" clearable size="small">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="自提" :value="1"></el-option>
                    <el-option label="快递" :value="2"></el-option>
                </el-select>
                <el-select v-model="searchData.status" placeholder="请选择发货状态" clearable size="small">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="待发货/取货" :value="1"></el-option>
                    <el-option label="已发货/取货" :value="2"></el-option>
                </el-select>
                <el-date-picker size="small" v-model="searchData.tm" type="daterange" value-format="yyyy-MM-dd" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                <el-select v-model="searchData.pay_source" placeholder="订单渠道" clearable size="small">
                    <el-option v-for="(item,k) in $pay_source" :key="k" :label="item" :value="parseInt(k)"></el-option>
                </el-select>
            </template>
            <template #column>
                <el-table-column prop="" label="商品名称">
                    <template v-slot="{ row }">{{ row.item.names }}</template>
                </el-table-column>
                <el-table-column prop="send_type_text" label="发货方式" />
                <el-table-column prop="order_no" label="订单号" width="240" />
                <el-table-column prop="phone" label="手机号" />
                <el-table-column prop="pay_source_text" label="订单渠道" />
                <el-table-column prop="order_price" label="订单金额" />
                <el-table-column prop="pay_price" label="实付金额" />
                <el-table-column prop="status_text" label="状态" />
                <el-table-column prop="send_company" label="发货快递/派送人" width="180" align="center">
                    <template v-slot="{ row }">{{ row.send_company || '-' }}<br/>{{ row.send_no }}</template>
                </el-table-column>
                <el-table-column prop="create_time" label="下单时间" width="180"/>
                <el-table-column label="操作" width="220">
                    <template v-slot="{ row }">
                        <el-button size="small" plain @click="view(row)">详情</el-button>
                        <el-button v-if="row.send_type == 2 && row.status == 1" size="small" plain @click="send(row)">发货</el-button>
                        <el-button v-if="row.send_type == 1 && row.status == 1" size="small" plain @click="finish(row)">完成</el-button>
                        <el-button v-if="row.status == 1 || (row.send_type == 2 && row.status == 2)" size="small" plain @click="cancel(row)">取消</el-button>
                    </template>
                </el-table-column>
            </template>
        </tableview>  
        <!-- 详情 -->
        <el-dialog title="订单详情" :visible.sync="viewShow" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
            <div style="display: flex;">
                <el-descriptions title="基本信息" :column="1">
                    <el-descriptions-item label="订单号">{{ viewData.order_no }}</el-descriptions-item>
                    <el-descriptions-item label="下单时间">{{ viewData.create_time }}</el-descriptions-item>
                    <el-descriptions-item label="订单渠道">{{ viewData.pay_source_text }}</el-descriptions-item>
                    <el-descriptions-item label="支付方式">{{ viewData.pay_source_text }}</el-descriptions-item>
                    <el-descriptions-item label="商品名称">{{ viewData.item.names }}</el-descriptions-item>
                    <el-descriptions-item label="商品价格">{{ viewData.item.price }}</el-descriptions-item>
                    <el-descriptions-item label="运费">{{ viewData.item.send_price }}</el-descriptions-item>
                    <el-descriptions-item label="订单金额">{{ viewData.order_price }}</el-descriptions-item>
                    <el-descriptions-item label="实付金额">{{ viewData.pay_price }}</el-descriptions-item>
                    <el-descriptions-item label="订单状态">{{ viewData.status_text }}</el-descriptions-item>
                    <el-descriptions-item label="完成时间">{{ viewData.send_time || '-' }}</el-descriptions-item>
                    <el-descriptions-item label="取消时间">{{ viewData.cancel_time || '-' }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="发货信息" :column="1">
                    <el-descriptions-item label="发货方式">{{ viewData.send_type_text }}</el-descriptions-item>
                    <template v-if="viewData.send_type == 1">
                        <el-descriptions-item label="预约领取场站">{{ viewData.item.group_name }}</el-descriptions-item>
                        <el-descriptions-item label="预约领取时间">{{ viewData.recv_address }}</el-descriptions-item>
                        <el-descriptions-item label="用户联系电话">{{ viewData.recv_phone }}</el-descriptions-item>
                        <el-descriptions-item label="派送站长">{{ viewData.send_company }}</el-descriptions-item>
                        <el-descriptions-item label="完成派送站长">{{ viewData.recv_finish }}</el-descriptions-item>                  
                    </template>
                    <template v-if="viewData.send_type == 2">
                        <el-descriptions-item label="发货时间">{{ viewData.send_time || '-' }}</el-descriptions-item>
                        <el-descriptions-item label="发货快递">{{ viewData.send_company || '-' }}</el-descriptions-item>
                        <el-descriptions-item label="快递单号">{{ viewData.send_no || '-' }}</el-descriptions-item>
                        <el-descriptions-item label="收货人">{{ viewData.recv_user }}</el-descriptions-item>
                        <el-descriptions-item label="联系电话">{{ viewData.recv_phone }}</el-descriptions-item>
                        <el-descriptions-item label="收货地址">{{ viewData.recv_address }}</el-descriptions-item>
                    </template>
                </el-descriptions>
            </div>
        </el-dialog> 
        <!-- 操作发货 -->
        <el-dialog title="操作发货" :visible.sync="sendShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
            <el-form :model="sendData" :rules="sendRules" ref="editForm" label-width="120px" class="form">
                <el-form-item label="收货人">
                    {{ viewData.recv_user }}
                </el-form-item>
                <el-form-item label="联系电话">
                    {{ viewData.recv_phone }}
                </el-form-item>
                <el-form-item label="收货地址">
                    {{ viewData.recv_address }}
                </el-form-item>
                <el-form-item label="快递公司" prop="send_company">
                    <el-input v-model="sendData.send_company" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="快递单号" prop="send_no">
                    <el-input v-model="sendData.send_no" size="mini"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveSend()">保 存</el-button>
                <el-button size="small" plain icon="el-icon-close" @click="editDialogShow = false">取 消</el-button>
            </div>
        </el-dialog>  
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Vue from 'vue'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import tableview from '@/components/tableview.vue'
import { get_types, tags, status_vals } from '../energy/const.js'

const searchData = ref({});
const table = ref(null);
const viewShow = ref(false);
const viewData = ref({item:{}});
const sendShow = ref(false);
const sendData = ref({});

const sendRules = ref({
	send_company: [{
		required: true,
		message: '请输入快递公司',
		trigger: 'change'
	}],
	send_no: [{
		required: true,
		message: '请输入快递单号',
		trigger: 'change'
	}],
});

onMounted(()=>{
});

// 处理数据
function checkFun(data){
    for(let d of data){

    }
}
// 订单详情
function view(row) {
    viewShow.value = true;
    viewData.value = row;
}
function send(row){ //发货
    viewData.value = row;
    sendData.value = {order_no:row.order_no, send_company:'', send_no:''};
    sendShow.value = true;
}
// 保存发货信息
const editForm = ref(null);
function saveSend() {
	editForm.value.validate(async valid => {
		if (valid) {
			let params = Object.assign({}, sendData.value);
			await API.Market.shop.send(params);
			Notification({title: '成功',message: '操作成功',type: 'success'});
			sendShow.value = false;
			table.value.getlist();
		}
	})
}

function finish(row){ //自提的完成订单
    MessageBox.confirm('确认完成此订单吗？', '完成送货', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确认完成',
		cancelButtonText: '关闭',
		type: 'warning'
	}).then(async () => {
		await API.Market.shop.finish({order_no: row.order_no});
		Notification({title: '成功',message: '操作成功',type: 'success'})
		table.value.getlist();
	});
}

function cancel(row){ //取消订单
    MessageBox.confirm('订单取消后将自动退款给用户，确认取消订单吗？', '取消订单', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确认取消订单',
		cancelButtonText: '关闭',
		type: 'warning'
	}).then(async () => {
		await API.Market.shop.cancel({order_no: row.order_no})
		Notification({title: '成功',message: '操作成功',type: 'success'})
		table.value.getlist();
	});
}

</script>

