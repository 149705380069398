<template>
    <div class="page">
        <el-tabs v-model="activeName">
			<el-tab-pane label="优选订单" name="third">
				<order></order>
			</el-tab-pane>
			<el-tab-pane label="积分订单" name="first">
                <Tables ref="tableList" :tableData="log_List" :totalPage="log_totalPage" :isLoading="isLoading" :searchFormData="log_searchFormData" :showOutput="true" :showRefresh="true" :showSearch="true" @getList="getLog" @clearSearch="clearSearch_log">
                    <!--搜索自定义内容-->
					<el-form-item slot="search-item">
                        <el-input v-model="log_searchFormData.id" size="small" clearable placeholder="按订单号查询">
                        </el-input>
                    </el-form-item>
                    <el-form-item slot="search-item">
                        <el-input v-model="log_searchFormData.phone" size="small" clearable placeholder="按手机号查询">
                        </el-input>
                    </el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="log_searchFormData.city" size="small" clearable placeholder="按用户来源查询" style="width: 140px;">
							<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="k"></el-option>
						</el-select>
                    </el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="log_searchFormData.get_type" size="small" clearable placeholder="商品类型" style="width: 120px;">
							<el-option v-for="(item,k) in get_types" :key="k" :label="item" :value="k"></el-option>
						</el-select>
                    </el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="log_searchFormData.status" size="small" clearable placeholder="订单状态" style="width: 120px;">
							<el-option v-for="(item,k) in get_status_vals" :key="k" :label="item" :value="k"></el-option>
						</el-select>
                    </el-form-item>
					<el-form-item slot="search-item">
						<el-date-picker unlink-panels v-model="log_searchFormData.tm" size="small" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始支付时间" end-placeholder="结束支付时间" style="width: 240px;">
						</el-date-picker>
                    </el-form-item>
                    <!--表格内容-->
                    <vxe-table-column slot="table-item" field="id" title="订单号" />
                    <vxe-table-column slot="table-item" field="phone" title="用户手机" />
                    <vxe-table-column slot="table-item" field="city_text" title="用户来源" />
                    <vxe-table-column slot="table-item" field="names" title="购买商品" />
					<vxe-table-column slot="table-item" field="get_type_text" title="商品类型" />
                    <vxe-table-column slot="table-item" field="num" title="支付积分" />
                    <vxe-table-column slot="table-item" field="tm" title="支付时间" width="180" />
                    <vxe-table-column slot="table-item" field="platform_text" title="支付方式" />
					<vxe-table-column slot="table-item" field="status_text" title="订单状态" />
					<vxe-table-column slot="table-item" title="操作" width="230">
						<template v-slot="{row}">
							<template v-if="row.get_type == 2 || row.get_type == 3">
								<el-button size="small" plain @click="detail(row)">详情</el-button>
								<el-button v-if="row.status == 0" size="small" plain @click="finish(row, 1)">完成</el-button>
								<el-button v-if="row.status == 0" size="small" plain @click="finish(row, 2)">取消</el-button>
							</template>
							<span v-else>-</span>
						</template>
					</vxe-table-column>
                </Tables> 
            </el-tab-pane>
            <el-tab-pane label="积分兑换统计" name="second">
                <Tables ref="xTable" :tableData="statistics_List" :totalPage="statistics_totalPage" :isLoading="isLoading" :searchFormData="statistics_searchFormData" :showRefresh="true" :showSearch="true" @getList="getStatistics" @clearSearch="clearSearch_statistics">
                    <!--搜索自定义内容-->
                    <el-form-item slot="search-item">
                        <el-date-picker v-model="statistics_searchFormData.tm" clearable type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始日期"  end-placeholder="结束日期" size="small">
                        </el-date-picker>
                    </el-form-item>
                    <!--表格内容-->
                    <vxe-table-column slot="table-item" field="names" title="商品名称" />
                    <vxe-table-column slot="table-item" field="exnum" title="兑换数量" />
                    <vxe-table-column slot="table-item" field="exEnergy" title="兑换积分" />
                </Tables> 
            </el-tab-pane>
        </el-tabs>
		<!-- 编辑 -->
        <el-dialog title="订单详情" v-loading="isLoading" :visible.sync="detailShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-descriptions title="基本信息" :column="1">
    			<el-descriptions-item label="订单号">{{ detailData.id }}</el-descriptions-item>
				<el-descriptions-item label="用户手机号">{{ detailData.phone }}</el-descriptions-item>
				<el-descriptions-item label="用户来源">{{ detailData.city_text }}</el-descriptions-item>
				<el-descriptions-item label="购买商品">{{ detailData.names }}</el-descriptions-item>
				<el-descriptions-item label="购买类型">{{ detailData.get_type_text }}</el-descriptions-item>
				<el-descriptions-item label="支付积分">{{ detailData.num }}</el-descriptions-item>
				<el-descriptions-item label="支付时间">{{ detailData.tm }}</el-descriptions-item>
				<el-descriptions-item label="订单状态">{{ detailData.status_text }}</el-descriptions-item>
				<el-descriptions-item label="完成时间">{{ detailData.status == 1 ? detailData.get_time : '-' }}</el-descriptions-item>
				<el-descriptions-item label="取消时间">{{ detailData.status == 2 ? detailData.get_time : '-' }}</el-descriptions-item>
			</el-descriptions>
			<el-descriptions title="其它信息" :column="1">
				<template v-if="detailData.get_type == 2">
					<el-descriptions-item label="预约领取场站">{{ detailData.ext.group_name }}</el-descriptions-item>
					<el-descriptions-item label="预约领取时间">{{ detailData.ext.get_date }} {{ detailData.ext.get_hour }}:00-{{ Number(detailData.ext.get_hour)+1 }}:00</el-descriptions-item>
					<el-descriptions-item label="用户联系电话">{{ detailData.ext.get_phone }}</el-descriptions-item>
					<el-descriptions-item label="派送站长">{{ detailData.ext.group_manager }}</el-descriptions-item>
					<el-descriptions-item label="完成派送站长">{{ detailData.ext.get_manager_name||'-' }}</el-descriptions-item>
				</template>
				<template v-if="detailData.get_type == 3">
					<el-descriptions-item label="商家名称">{{ detailData.ext.business_names }}</el-descriptions-item>
					<el-descriptions-item label="商家地址">{{ detailData.ext.business_address }}</el-descriptions-item>
					<el-descriptions-item label="商家电话">{{ detailData.ext.business_phone }}</el-descriptions-item>
				</template>
			</el-descriptions>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message, Loading} from 'element-ui'
import Tables from '@/components/tables'
import XLSX from "xlsx"
import { get_types, get_status_vals } from './const.js';
import order from '@/views/market-management/shop/order'

const activeName = ref("first");
const isLoading = ref(false);

const log_totalPage = ref(0);
const log_searchFormData = ref({});
const log_List = ref([]);
let business_list = [];
let group_list = [];
onMounted(()=>{
	API.Market.Energy.Business.list({size:1000}).then((res)=>{
		business_list = res.data;
	});
	API.Device.GetGroupMinList({getmanager:1}).then((res)=>{
		group_list = res;
	});
	tableList.value.exportTable = ()=>{
		let workbook = XLSX.utils.book_new();
		let arr = [["订单号","用户手机","用户来源","购买商品","商品类型","支付积分","支付时间","支付方式","订单状态","预约领取","场站","预约时间","用户联系电话","派送站长","完成派送站长","完成时间","取消时间","服务商家"]];
        for(let v of log_List.value){
			let group_name = '', group_manager = '', business_names = '';
			if(v.get_type == 2){
				for(let g of group_list){
					if(g.group_id == v.ext.group_id){
						group_name = g.group_name;
						group_manager = g.manager;
						break;
					}
				}
			}else if(v.get_type == 3){
				for(let b of business_list){
					if(b.id == v.ext.business_id){
						business_names = b.names;
					}
				}
			}
            let val = [v.id,v.phone,v.city_text,v.names,v.get_type_text,v.num,v.tm,v.platform_text,v.status_text,v.ext.get_date,group_name,v.get_type==2?v.ext.get_hour+":00~"+(Number(v.ext.get_hour)+1)+":00":'',v.ext.get_phone,group_manager,v.ext.get_manager_name,v.status==1?v.get_time:'-',v.status==2?v.get_time:'-',business_names];
            arr.push(val);
        }
        let sheet = XLSX.utils.aoa_to_sheet(arr);
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1", true);
        XLSX.writeFile(workbook, "out.xlsx");
	}
});
// 表格列表
async function getLog(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, log_searchFormData.value);
	isLoading.value = true
	const res = await API.Market.Energy.Shop.log(params);
    for(let d of res.data){
        d.city_text = Vue.prototype.$userArea[d.city];
        d.platform_text = Vue.prototype.$platform[d.platform];
		if(d.ext){
			d.ext = JSON.parse(d.ext);
			d.get_type_text = get_types[d.ext.get_type];
			if(d.ext.get_type == 1){
				d.status = 1;
			}		
		}else if(d.get_type){
			d.get_type_text = get_types[d.get_type];
			if(d.get_type == 1){
				d.status = 1;
			}	
		}else{
			d.status = 1;
		}
		d.status_text = get_status_vals[d.status];
    }
	log_List.value = res.data;
	log_totalPage.value = res.total;
	isLoading.value = false;
    
    setTimeout(()=>{
		let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
		for(let nd of htmlnode){
			nd.style.height = 'auto';
		}
		htmlnode = document.getElementsByTagName("table");
		for(let nd of htmlnode){
			nd.style.width = '100%';
		}
	}, 200);
}
// 重置搜索
function clearSearch_log() {
	log_searchFormData.value = {}
}

const statistics_totalPage = ref(0);
const statistics_searchFormData = ref({});
const statistics_List = ref([]);
// 表格列表
async function getStatistics(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, statistics_searchFormData.value);
	isLoading.value = true
	const res = await API.Market.Energy.Shop.statistics(params);
    for(let d of res.data){
        d.city_text = Vue.prototype.$userArea[d.city];
    }
	statistics_List.value = res.data;
	statistics_totalPage.value = res.total;
	isLoading.value = false;
    
    setTimeout(()=>{
		let htmlnode = document.getElementsByClassName("vxe-table--body-wrapper");
		for(let nd of htmlnode){
			nd.style.height = 'auto';
		}
		htmlnode = document.getElementsByTagName("table");
		for(let nd of htmlnode){
			nd.style.width = '100%';
		}
	}, 200);
}
// 重置搜索
function clearSearch_statistics() {
	statistics_searchFormData.value = {}
}

const detailShow = ref(false);
const detailData = ref({});
//显示订单详情
function detail(row){
	detailData.value = {};
	detailShow.value = true;
	Loading.value = true;
	if(row.get_type == 3){
		API.Market.Energy.Business.view({id:row.ext.business_id}).then((res)=>{
			detailData.value = row;
			detailData.value.ext.business_names = res.names;
			detailData.value.ext.business_address = res.address;
			detailData.value.ext.business_phone = res.phone;
			Loading.value = false;
		});
	}else if(row.get_type == 2){
		API.Device.GetGroupDetail({token: store.state.user.token, group_id:row.ext.group_id}).then((res)=>{
			detailData.value = row;
			detailData.value.ext.group_name = res.group_name;
			detailData.value.ext.group_manager = res.manager;
			Loading.value = false;
		});
	}else{
		Loading.value = false;
	}
}
//操作订单
const tableList = ref(null);
function finish(row, status){
	let fun = async () => {
		const params = {
			token: store.state.user.token,
			tm: row.tm,
			order_id: row.order_id,
			status: status
		}
		await API.Market.Energy.Shop.finish(params)
		Notification({title: '成功',message: '操作成功',type: 'success'})
		tableList.value.refreshTable();
	};
	if(status == 1){
		MessageBox.confirm('确定要完成该订单吗', '提示', {
			cancelButtonClass: 'btn-custom-cancel',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(fun);		
	}else{
		MessageBox.confirm('确定要取消该订单吗', '提示', {
			cancelButtonClass: 'btn-custom-cancel',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(fun);
	}
}
</script>

